import { Controller } from "stimulus"

export default class extends Controller {
  initialize(){
    setTimeout(function() {
      const hash = window.location.hash;
      if(hash == '#new') {
        $('#brand-modal').modal('show');
      }
    }, 500)
  }
}