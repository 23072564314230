import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'brand',
    'brand_id',
    'campaign',
    'campaign_id',
    'start_date',
    'end_date'
  ];
  initialize() {
    this.initializeCampaignAutocomplete();
    this.initializeBrandAutocomplete();
    this.initializeDatepickers();
  }
  initializeDatepickers() {
    $(".datetimepicker").datetimepicker({
    	autoclose: true,
    	componentIcon: '.s7-date',
    	navIcons:{
    		rightIcon: 's7-angle-right',
    		leftIcon: 's7-angle-left'
    	}
    });
  }

  initializeCampaignAutocomplete() {
    const that = this;
    const url = '/agency/campaign/autocomplete';
    $(that.campaignTarget).select2({
      ajax: {
        url: url,
        dataType: 'json',
        placeholder: 'Search for a campaign',
        minimumInputLength: 3,
        processResults: function (data) {
          return {
            results: data
          };
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      tags: true,
      createSearchChoice:function(term, data) {
        if ($(data).filter(function() {
          return this.text.localeCompare(term)===0;
        }).length===0) {
          return {id:term, text:term};
        }
      }
    });
    $(that.campaignTarget).on('select2:select', function(e) {
      const data = e.params.data;
      $(that.campaign_idTarget).val(data.id);
      if (Number.isFinite(data.id)) {
        $(that.campaign_idTarget).val(data.id);
      }else{
        $(that.campaign_idTarget).val(0);
      }
    });
  }
  initializeBrandAutocomplete() {
    const that = this;
    const url = '/agency/brand/autocomplete';
    $(this.brandTarget).select2({
      ajax: {
        url: url,
        dataType: 'json',
        placeholder: 'Search for a brand',
        minimumInputLength: 3,
        processResults: function (data) {
          return {
            results: data
          };
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      tags: true,
      createSearchChoice:function(term, data) {
        if ($(data).filter(function() {
          return this.text.localeCompare(term)===0;
        }).length===0) {
          return {id:term, text:term};
        }
      }
    });
    $(that.brandTarget).on('select2:select', function(e) {
      const data = e.params.data;
      if (Number.isFinite(data.id)) {
        $(that.brand_idTarget).val(data.id);
      }else{
        $(that.brand_idTarget).val(0);
      }
    });
  }
  loadCampaignDates(campaign_id, that) {
    const url = `/agency/campaigns/${campaign_id}`;
    $.ajax({
      url:url,
      success: function(camera) {
        $(that.startDateTarget).val(camera.start_date);
        $(that.endDateTarget).val(camera.end_date);
      },
      method: 'get',
      dataType: 'json'
    });
  }
}