import { Controller } from "stimulus";
import Taggle from "taggle";

export default class extends Controller {
  static targets = ['brand', 'brand_id']
  initialize() {
    this.initializeBrandAutocomplete();
  }
  initializeBrandAutocomplete() {
    const that = this;
    const url = '/agency/brand/autocomplete';
    $(this.brandTarget).select2({
      ajax: {
        url: url,
        dataType: 'json',
        placeholder: 'Search for a brand',
        minimumInputLength: 3,
        processResults: function (data) {
          return {
            results: data
          };
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      multiple: false,
      tags: false,
    });
  }
}