import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    const that = this;
    var data = [
      { label: "Complete", data: $(that.element).attr('data-complete')},
      { label: "Incomplete", data: $(that.element).attr('data-incomplete')},
    ];
    $.plot(that.element, data, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.70,
          shadow:{
            top: 5,
            left: 15,
            alpha:0.3
          },
          stroke:{
            width:0
          },
          highlight:{
            opacity: 0.08
          },
          label: {
            show: false
          }
        }
      },
      colors: ['#45d8c2', '#f0f0f0'],
      legend: {
        show: false
      }
    });
  }
}
