import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'location',
  ]
  initialize() {
    this.initializeMap(this)
  }

  initializeMap(that) {
    that.map = null;
    let latitude = $(that.element).attr('data-latitude');
    console.log(latitude);
    let longitude = $(that.element).attr('data-longitude');
    latitude = parseFloat(latitude);
    console.log(latitude);
    longitude = parseFloat(longitude);
    const position = { lat: latitude, lng: longitude };
    console.log(position);
    const mapOptions = {
      zoom: 14,
      center: position,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    that.map = new google.maps.Map(document.getElementById('location-map'), mapOptions);
    that.marker = new google.maps.Marker({
      position: position,
      map: that.map,
    });
    window.status = 'done_with_map';
  }
}