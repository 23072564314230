import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'total', 'quantity', 'totalInput']
  openModal(event) {
    event.preventDefault()
    event.stopPropagation()
    $(this.modalTarget).modal('show');
    return false
  }
  updateTotal() {
    let quantity = parseInt($(this.quantityTarget).val());
    if(isNaN(quantity)){
      quantity = 0;
    }
    const cost = parseFloat($(this.totalTarget).attr('data-cost'));
    $(this.totalTarget).html(`$ ${quantity * cost}`);
    $(this.totalInputTarget).val(quantity * cost);
  }
}