import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.initializeStream();
  }
  initializeStream() {
    const that = this;
    //const url = 'http://rtmp.intellion.ai:8080/hls/qg_003.m3u8';//$(this.element).attr('data-url');
    const url = $(this.element).attr('data-url');
    const video = $(this.element).find('video')[0];
    if(Hls.isSupported()) {
      const config = {
        manifestLoadingMaxRetry: 1000,
        manifestLoadingRetryDelay: 5000,
        manifestLoadingMaxRetryTimeout : 5000,
      };
      that.hls = new Hls(config);
      that.hls.on(Hls.Events.LEVEL_LOADED, that.onLevelLoaded);

      that.hls.on(Hls.Events.ERROR, function (_, data) {
        if (data.fatal) {
          $(video).addClass('error-reconnecting');
          switch(data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            setTimeout(()=>{
              that.loadHLSVideo(video, that.hls, url)
            }, 5000)
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error(data)
            setTimeout(()=>{
              that.loadHLSVideo(video, that.hls, url)
            }, 5000)
            break;
          default:
            that.hls.destroy();
            break;
          }
        }
      });
      that.loadHLSVideo(video, that.hls, url)
    }
  }
  loadHLSVideo(video, hls, url){
    hls.loadSource(url);
    hls.attachMedia(video);
    hls.on(Hls.Events.MANIFEST_PARSED,function() {
      video.play();
    });
  }
  onLevelLoaded(_, __){
    $(this.media).removeClass('error-reconnecting');
  }
  fullScreen(event) {
    event.preventDefault();
    const video = $(this.element).find('video')[0];
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) { /* Firefox */
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) { /* IE/Edge */
      video.msRequestFullscreen();
    }
  }
  remove(event) {
    event.preventDefault();
    $(this.element).parent().remove();
  }
}