import { Controller } from "stimulus";
import Taggle from "taggle";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['modal', 'id', 'emails','form']
  initialize() {
    this.initializeTaggle(this);
    const that = this;
    $(this.modalTarget).on('hidden.bs.modal', function(){
      that.resetModal();
    });
  }

  initializeTaggle(that) {
    window.emailsTaggle = new Taggle(that.emailsTarget, { placeholder: '', hiddenInputName: 'emails[]', allowDuplicates: false });
  }

  save(event){
    event.preventDefault()
    if($('input[name="emails[]"]').length > 0){
      const form = $(this.formTarget).find('form')[0];
      Rails.fire(form, 'submit');
    }else{
      const message = $(this.modalTarget).attr('data-contacts-message');
      alert(message);
    }
  }

  resetModal() {
    const that = this;
    // $(that.emailsTarget).find('.taggle').remove()
    window.emailsTaggle.removeAll()
  }
}