import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['list', 'keypad']
  toggle(event) {
    const target = $(event.currentTarget).attr('data-view');
    if(target == 'list') {
      // $('#table-view').show();
      // $('#video-view').hide();
      window.location.href = '/agency/cameras#list';
    }else{
      // $('#table-view').hide();
      // $('#video-view').show();
      window.location.href = '/agency/cameras#streams';
    }
    location.reload();
  }
}