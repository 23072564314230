import { Controller } from "stimulus"

export default class extends Controller {
  toggleAll(e) {
    if ($(e.currentTarget).prop('checked')) {
      $('input[name="delete_element"').prop('checked', true);
    }else {
      $('input[name="delete_element"').prop('checked', false);
    }
  }
}