import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'chartContainer'
  ]
  initialize() {
    this.renderChart(this);
    console.log('Initialized');
  }
  renderChart(that){
    const data = JSON.parse(this.chartContainerTarget.dataset.info);

    $.plot(that.chartContainerTarget, data, {
      series: {
        pie: {
          show: true,
          innerRadius: 0.5,
          shadow:{
            top: 5,
            left: 15,
            alpha:0.3
          },
          stroke:{
            width:0
          },
          highlight:{
            opacity: 0.08
          }
        }
      },
      grid: {
        hoverable: true,
        clickable: true
      },
      legend: {
        show: false
      }
    });
  }
}