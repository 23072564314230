import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['table', 'campaigns']
  initialize() {
    $(this.campaignsTarget).select2();
    $(this.campaignsTarget).select2();
    const that = this;
    $(this.campaignsTarget).on('change',function(){
      that.loadAdsArtsForCampaign(that);
    });
  }
  loadAdsArtsForCampaign(that) {
    // const that = this;
    const id = $(that.campaignsTarget).val();
    let url = `/agency/brand/ads_arts?partial=true`;
    if(id) {
      url = `${url}&campaign_id=${id}`;
    }
    $.get(url, function(html) {
      $(that.tableTarget).replaceWith(html);
    });
  }
}