import { Controller } from "stimulus";
import Taggle from "taggle";

export default class extends Controller {
  static targets = ['asset', 'asset_id']
  initialize() {
    this.initializeAssetAutocomplete();
  }
  initializeAssetAutocomplete() {
    const that = this;
    const url = '/agency/asset/autocomplete';
    $(this.assetTarget).select2({
      ajax: {
        url: url,
        dataType: 'json',
        placeholder: 'Search for a asset',
        minimumInputLength: 3,
        processResults: function (data) {
          return {
            results: data
          };
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      multiple: true,
      tags: false,
    });
  }
}