import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    setTimeout(function() {
      $('.grid-view').addClass('d-none');
    }, 50)
    this.map();
  }
  centerMap(event) {
    console.log(event);
    const latitude = parseFloat($(event.currentTarget).attr('data-latitude'));
    const longitude = parseFloat($(event.currentTarget).attr('data-longitude'));
    if(latitude){
      const position = { lat: latitude, lng: longitude };
      window.assets_map.setCenter(position);
    }
  }
  toggle(event) {
    const list = $(event.target).attr('data-list');
    $('.grid-view').addClass('d-none');
    $('.list-view').addClass('d-none');
    $('.map-view').addClass('d-none');
    $(`.${list}-view`).removeClass('d-none');
    // const that = this;
    if(list == 'map') {
      setTimeout(function(){
        $(`.map-view`).find('.asset').each(function(){
          const latitude = parseFloat($(this).attr('data-latitude'));
          const longitude = parseFloat($(this).attr('data-longitude'));
          const position = { lat: latitude, lng: longitude };
          window.assets_markers.push(new google.maps.Marker({
            position: position,
            map: window.assets_map,
          }))
          window.assets_map.setCenter(position);
        });
      },50)
    }
  }
  map() {
    window.assets_map = null;
    window.assets_markers = [];
    // latitude = parseFloat(latitude);
    // longitude = parseFloat(longitude);
    // const position = { lat: latitude, lng: longitude };
    const mapOptions = {
      zoom: 14,
      // center: position,
      center: new google.maps.LatLng(19.39068,-99.2836964),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    window.assets_map = new google.maps.Map(document.getElementById('assets-map'), mapOptions);
  }
}