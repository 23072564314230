import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'chartContainer'
  ]
  initialize() {
    this.renderChart(this);
    console.log('Initialized');
  }
  randValue() {
    return (Math.floor(Math.random() * (1 + 50 - 20))) + 10;
  }

  renderChart(that){

    const data_com2 = [
      [1, that.randValue()],
      [2, that.randValue()],
      [3, 2 + that.randValue()],
      [4, 3 + that.randValue()],
      [5, 5 + that.randValue()],
      [6, 10 + that.randValue()],
      [7, 15 + that.randValue()],
      [8, 20 + that.randValue()],
      [9, 25 + that.randValue()],
      [10, 30 + that.randValue()],
      [11, 35 + that.randValue()],
      [12, 25 + that.randValue()],
      [13, 15 + that.randValue()],
      [14, 20 + that.randValue()],
      [15, 45 + that.randValue()],
      [16, 50 + that.randValue()],
      [17, 65 + that.randValue()],
      [18, 70 + that.randValue()],
      [19, 85 + that.randValue()],
      [20, 80 + that.randValue()],
      [21, 75 + that.randValue()],
      [22, 80 + that.randValue()],
      [23, 75 + that.randValue()]
    ];
    const data_com = [
      [1, that.randValue()],
      [2, that.randValue()],
      [3, 10 + that.randValue()],
      [4, 15 + that.randValue()],
      [5, 20 + that.randValue()],
      [6, 25 + that.randValue()],
      [7, 30 + that.randValue()],
      [8, 35 + that.randValue()],
      [9, 40 + that.randValue()],
      [10, 45 + that.randValue()],
      [11, 50 + that.randValue()],
      [12, 55 + that.randValue()],
      [13, 60 + that.randValue()],
      [14, 70 + that.randValue()],
      [15, 75 + that.randValue()],
      [16, 80 + that.randValue()],
      [17, 85 + that.randValue()],
      [18, 90 + that.randValue()],
      [19, 95 + that.randValue()],
      [20, 100 + that.randValue()],
      [21, 110 + that.randValue()],
      [22, 120 + that.randValue()],
      [23, 130 + that.randValue()]
    ];

    const plot_statistics = $.plot($(that.chartContainerTarget), [{
      data: data_com, showLabels: true, label: "New Visitors", labelPlacement: "below", canvasRender: true, cColor: "#FFFFFF"
    },{
      data: data_com2, showLabels: true, label: "Old Visitors", labelPlacement: "below", canvasRender: true, cColor: "#FFFFFF"
    }
    ], {
      series: {
        lines: {
          show: true,
          lineWidth: 1.5,
          fill: false,
          fillColor: { colors: [{ opacity: 0.5 }, { opacity: 0.5}] },
        },
        fillColor: "rgba(0, 0, 0, 1)",
        points: {
          show: false,
          fill: true
        },
        shadowSize: 0
      },
      legend:{
        show: false
      },
      grid: {
        show: true,
        margin: {
          top: -20,
          bottom: 0,
          left: 0,
          right: 0,
        },
        labelMargin: 0,
        axisMargin: 0,
        hoverable: true,
        clickable: true,
        tickColor: "rgba(0,0,0,0)",
        borderWidth: 0,
        minBorderMargin: 0
      },
      xaxis: {
        autoscaleMargin: 0,
        ticks: 11,
        tickDecimals: 0
      },
      yaxis: {
        autoscaleMargin: 0.2,
        ticks: 5,
        tickDecimals: 0
      }
    });
  }
}