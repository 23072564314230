import { Controller } from "stimulus"

export default class extends Controller {
  change(e) {
    const that = this;
    const value = $(e.currentTarget).val();
    const confirm_text = $(e.currentTarget).attr('confirm_text');
    if(value != '') {
      switch(value) {
        case 'delete':
          if(confirm(confirm_text)) {
            that.deleteAll();
          }else{
            $(e.currentTarget).prop('selectedIndex',0);
          }
          break;
      }
    }
  }
  deleteAll() {
    const url = '/agency/brand/users_delete_many.js';
    const ids = [];
    $('input[name="delete_element"').each(function() {
      if ($(this).prop('checked')) {
        ids.push($(this).val());
      }
    });
    $.ajax({
      url: url,
      data: { ids: ids },
      method: 'delete'
    });
  }
}