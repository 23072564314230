import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  connect() {
    this.initializeDraggable();
    this.initializeDroppable();
  }
  initializeDraggable() {
    $( ".draggable" ).draggable(
      {
        opacity: 0.7,
        helper: "clone",
        revert: "invalid"
      }
    );
  }
  initializeDroppable() {
    const that = this;
    $( ".droppable" ).droppable({
      accept: ".draggable",
      drop: function(event, ui) {
        that.handleDrop(event, ui, that, this);
      }
    });
  }
  handleDrop(_, ui, that, destination) {
    const alias = $(ui.helper[0]).text();
    const id = $(ui.draggable[0]).attr('data-id');
    const mac = $(ui.draggable[0]).attr('class').split(" ")[0];
    // const mac = 'qg_003';
    const controller = 'cameras-stream';

    const container = that.createContainer();
    const stream_container = that.createStreamContainer(id, mac, controller);
    const video = document.createElement('video');
    const full_screen_icon_container = that.createFullScreenIcon(controller);
    const remove_icon_container = that.createRemoveIcon(controller);
    const description_container = that.createDescription(alias, full_screen_icon_container, remove_icon_container);

    $(stream_container).append(video);
    $(stream_container).append(description_container);
    $(container).append(stream_container);
    $(destination).append(container);
  }
  createContainer() {
    const container = document.createElement('div');
    $(container).addClass('item');
    return container;
  }
  createStreamContainer(id, mac, controller) {
    const stream_container = document.createElement('div');
    $(stream_container).addClass('photo');
    $(stream_container).attr('data-url', `${window.base_stream_url}${mac}.m3u8`);
    $(stream_container).attr('data-id', `${id}`);
    $(stream_container).attr('data-controller', `${controller}`);
    return stream_container;
  }
  createDescription(alias, full_screen_icon_container, remove_icon_container) {
    const description_container = document.createElement('div');
    $(description_container).addClass('description');
    const description = document.createElement('div');
    $(description).addClass('desc');
    $(description).html(`<span>${alias}</span>`);
    $(description_container).append(full_screen_icon_container);
    $(description_container).append(description);
    $(description_container).append(remove_icon_container);
    return description_container;
  }
  createFullScreenIcon(controller) {
    const action = `click->${controller}#fullScreen`;
    const full_screen_icon_container = document.createElement('div');
    $(full_screen_icon_container).attr('data-action', action);
    $(full_screen_icon_container).addClass('icon');
    const full_screen_icon_link = document.createElement('a');
    const full_screen_icon = document.createElement('i');
    $(full_screen_icon).addClass('s7-expand1');
    $(full_screen_icon_link).append(full_screen_icon);
    $(full_screen_icon_container).append(full_screen_icon_link);
    return full_screen_icon_container;
  }
  createRemoveIcon(controller) {
    const action = `click->${controller}#remove`;
    const remove_icon_container = document.createElement('div');
    $(remove_icon_container).attr('data-action', action);
    $(remove_icon_container).addClass('icon');
    const remove_icon_link = document.createElement('a');
    const remove_icon = document.createElement('i');
    $(remove_icon).addClass('s7-trash');
    $(remove_icon_link).append(remove_icon);
    $(remove_icon_container).append(remove_icon_link);
    return remove_icon_container;
  }
  save(event) {
    const that = this;
    event.preventDefault();
    event.stopPropagation();
    let position = 0;
    const form = $(this.element).find('form');
    form.find('.remove-on-save').remove();
    $(this.element).find('.photo').each(function() {
      const id = $(this).attr('data-id');
      const position_element = that.createCameraPositionFormElement(position);
      const camera_id_element = that.createCameraIdFormElement(position, id);
      form.prepend(position_element);
      form.prepend(camera_id_element);
      position++;
    });
    Rails.fire($(event.currentTarget).parents('form')[0], 'submit');
  }
  createCameraPositionFormElement(position) {
    const element = document.createElement('input');
    $(element).addClass('remove-on-save');
    $(element).attr('type', 'hidden');
    $(element).attr('name', `agency_panel[agency_panel_agency_cameras_attributes][${position}][position]`)
    $(element).attr('value', position);
    return element;
  }
  createCameraIdFormElement(position, camera_id) {
    const element = document.createElement('input');
    $(element).addClass('remove-on-save');
    $(element).attr('type', 'hidden');
    $(element).attr('name', `agency_panel[agency_panel_agency_cameras_attributes][${position}][agency_camera_id]`)
    $(element).attr('value', camera_id);
    return element;
  }
}