import { Controller } from "stimulus";
import Taggle from "taggle";
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['modal', 'brand', 'assetsNumber', 'ids', 'emails', 'form'];

  initialize() {
    const that = this;
    $(this.brandTarget).select2();
    $(this.modalTarget).on('shown.bs.modal', function() {
      that.checkForSelectedAssets(that);
    });
    this.initializeTaggle(this);
  }

  initializeTaggle(that) {
    window.emailsTaggle = new Taggle(that.emailsTarget, { placeholder: '', hiddenInputName: 'agency_proposal[emails][]', allowDuplicates: false });
  }

  checkForSelectedAssets(that) {
    const ids = [];
    $('#assets-table input:checkbox[name="delete_element"]').each(function() {
      if ($(this).prop('checked')) {
        ids.push($(this).val());
      }
    });
    if(ids.length == 0) {
      const message = $(that.modalTarget).attr('data-message');
      alert(message)
      $(that.modalTarget).modal('hide');
    }else{
      $(that.assetsNumberTarget).html(ids.length);
      $(that.idsTarget).val(ids.join(','));
    }
  }
  save(event) {
    event.preventDefault()
    if($('input[name="agency_proposal[emails][]"]').length > 0){
      const form = $(this.formTarget).find('form')[0];
      Rails.fire(form, 'submit');
    }else{
      const message = $(this.modalTarget).attr('data-contacts-message');
      alert(message);
    }
  }
}