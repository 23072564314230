import { Controller } from "stimulus";
import Taggle from "taggle";

export default class extends Controller {
  static targets = ['modal']
  initialize() {
    const that = this;
    $(this.modalTarget).on('hidden.bs.modal', function(){
      that.resetModal();
    });
  }
  openModal() {
    $(this.modalTarget).modal('show');
  }
  resetModal() {
    const that = this;
    const url = `/agency/brand/users/new`;
    $.get(url, function(html) {
      $('#user-modal').replaceWith(html);
      setTimeout(function(){
        $('#user-modal').on('hidden.bs.modal', function(){
          that.resetModal();
        });
      }, 500)
    });
  }
  edit(event) {
    const that = this;
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    const url = `/agency/brand/users/${id}/edit`;
    $.get(url, function(html) {
      $('#user-modal').replaceWith(html);
      setTimeout(function(){
        $('#user-modal').on('hidden.bs.modal', function(){
          that.resetModal();
        });
      }, 500)
      $('#user-modal').modal('show');
    });
  }
}