import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form']
  openModal() {
    $(this.modalTarget).modal('show');
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    this.loadCamera(id);
  }
  loadCamera(id) {
    const that = this;
    if(id) {
      const url = `/agency/cameras/${id}/edit`;
      $.get(url, function(html) {
        $(that.formTarget).replaceWith(html);
        that.openModal();
      });
    }
  }
}