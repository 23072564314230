import { Controller } from "stimulus"

export default class extends Controller {
  addCard() {
    // $("#add-card").on('click', function() {
      // const $form = $(this)
    const $form = $("#card-form")
    $form.find("button").prop("disabled", true)
    Conekta.token.create($form, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler)
    return false
    // })
  }

  conektaSuccessResponseHandler(token){
    const $form = $("#card-form")
    $form.append($("<input type=\"hidden\" name=\"conektaTokenId\" />").val(token.id))
    $form.get(0).submit()
  }

  conektaErrorResponseHandler(response) {
    const $form = $("#card-form")
    $form.find(".card-errors").text(response.message_to_purchaser)
    $form.find("button").prop("disabled", false)
  }
}