import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'wizard']
  connect() {
    App.wizard();
    $(this.modalTarget).on('hidden.bs.modal', this.resetWizard);
  }
  addCamera(event) {
    event.preventDefault();
    event.stopPropagation();
    const that = this;
    const url = '/agency/cameras/modal/wizard/steps/1';
    $.get(url, function(html) {
      $('#cameras-wizard-step-1').replaceWith(html);
      that.openModal();
    });
  }
  openModal() {
    $(this.modalTarget).modal('show');
  }
  cancel(event) {
    event.preventDefault();
    event.stopPropagation();
    const confirm_text = $(event.currentTarget).attr('data-confirm-text');
    if(confirm(confirm_text)) {
      $(this.modalTarget).modal('hide');
    }
  }
  resetWizard() {
    $('#camera-wizard').wizard('selectedItem', { step: 1 });
    const url = `/agency/cameras/modal/wizard/steps/1`;
    $.get(url, function(html) {
      $('#cameras-wizard-step-1').replaceWith(html);
    });
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    this.loadCamera(id);
  }
  loadCamera(id) {
    const that = this;
    if(id) {
      const url = `/agency/cameras/${id}/edit`;
      $.get(url, function(html) {
        $('#cameras-wizard-step-1').replaceWith(html);
        that.openModal();
      });
    }
  }
}