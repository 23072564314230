import { Controller } from "stimulus"
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

export default class extends Controller {
  click() {
    const that = this;
    const target_div_id = $(this.element).attr('data-target-div-id');
    const target_div = $(`#${target_div_id}`);
    const content = $(target_div)[0];
    const pdf = new jsPDF('', 'pt', 'letter');
    html2canvas(content).then(function(canvas) {
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(pageData, 'JPEG', 0, 0, 595.28, 592.28/canvas.width * canvas.height);
      pdf.save(`general-dashboard-${that.getDate()}.pdf`);
    });
  }
  getDate() {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    return `${dd}-${mm}-${yyyy}`;
  }
}