import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'asset']
  initialize() {
    const hash = location.hash;
    $(this.assetTarget).select2({});
    if(hash == '#list') {
      $('#video-view').hide();
    }else {
      $('#table-view').hide();
    }
  }
  addCamera(event) {
    event.preventDefault();
    event.stopPropagation();
    const that = this;
    const url = '/agency/cameras/new/';
    $.get(url, function(html) {
      $(that.modalTarget).replaceWith(html);
      that.openModal();
    });
  }
  openModal() {
    $(this.modalTarget).modal('show');
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    this.loadCamera(id);
  }
  destroy(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    const url = `/agency/cameras/${id}.js`;
    $.ajax({
      url: url,
      method: 'delete'
    });
  }
  loadCamera(id) {
    const that = this;
    if(id) {
      const url = `/agency/cameras/${id}/edit`;
      $.get(url, function(html) {
        $(that.modalTarget).replaceWith(html);
        that.openModal();
      });
    }
  }
}