import { Controller } from "stimulus"

export default class extends Controller {

  share(event) {
    event.preventDefault()
    const panel_id = $(event.target).attr('data-id');
    const url = `/agency/panels/${panel_id}/modal`
    $.ajax({
      url: url,
      success: function(html) {
        $('#share-modal-container').html(html);
        $('#share-panel-modal').modal('show');
      }
    });
  }
}