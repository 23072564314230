import { Controller } from "stimulus"

export default class extends Controller {
  default(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    const url = `/agency/settings/default_payment_source/${id}`;
    $.ajax({
      url: url,
      type: 'put',
    });
  }
  delete(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    const url = `/agency/settings/delete_payment_source/${id}`;
    $.ajax({
      url: url,
      type: 'DELETE',
    });
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    console.log(event);
  }
}