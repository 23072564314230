import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'dropdown', 'form']
  connect() {
    $(this.modalTarget).on('hidden.bs.modal', this.cancel);
  }
  click(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openModal();
  }
  cancel() {
    const that = $(this).find('.modal-content').first();
    const url = `/admin/registrations/new`;
    $.get(url, function(html) {
      $(that).html(html);
    });
  }
  openModal() {
    $(this.modalTarget).modal('show');
  }
  userTypeSelected(event) {
    event.preventDefault();
    event.stopPropagation();
    const user_type = $(this.dropdownTarget).val();
    const that = this;
    if(user_type) {
      const url = `/admin/registrations/new?user_type=${user_type}`;
      $.get(url, function(html) {
        $(that.formTarget).html(html);
      });
    } else {
      const url = `/admin/registrations/new`;
      $.get(url, function(html) {
        $(that.formTarget).html(html);
      });
    }
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    this.loadUser(id);
  }
  loadUser(id) {
    const that = this;
    if(id) {
      const url = `/admin/registrations/${id}/edit`;
      $.get(url, function(html) {
        $(that.formTarget).html(html);
        that.openModal();
      });
    }
  }
}