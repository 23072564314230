import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'trafficPerHour',
    'totalTraffic',
    'automobiles',
    'motorcycles',
    'buses',
    'trucks',
    'persons'
  ];
  connect() {
    // Uncomment this when the services are integrated
    // this.loadTrafficPerHour();
    // this.loadTotalTraffic();
    // this.loadAutomobiles();
    // this.loadMotorcycles();
    // this.loadBuses();
    // this.loadTrucks();
    // this.loadPersons();
    this.initDatepickers();
  }
  toggleClients(event) {
    event.preventDefault();
    if($('#clients-modal').hasClass('clients-modal-hide')){
      $('#clients-modal').removeClass('clients-modal-hide');
    }else {
      $('#clients-modal').addClass('clients-modal-hide');
    }
  }
  showLoading() {
    $('body').prepend('<div class="backdrop"><div class="lds-dual-ring"></div></div>');
  }
  initDatepickers() {
    $(".datetimepicker").datetimepicker({
    	autoclose: true,
    	componentIcon: '.s7-date',
    	navIcons:{
    		rightIcon: 's7-angle-right',
    		leftIcon: 's7-angle-left'
    	}
    });
  }
  loadTrafficPerHour() {
    const that = this;
    const url = `/agency/dashboards/components/traffic_per_hour${location.search}`;
    $.get(url, function(html) {
      $(that.trafficPerHourTarget).html(html);
    });
  }
  loadTotalTraffic() {
    const that = this;
    const url = `/agency/dashboards/components/total_traffic${location.search}`;
    $.get(url, function(html) {
      $(that.totalTrafficTarget).html(html);
    });
  }
  loadAutomobiles() {
    const that = this;
    const url = `/agency/dashboards/components/automobiles${location.search}`;
    $.get(url, function(html) {
      $(that.automobilesTarget).html(html);
    });
  }
  loadMotorcycles() {
    const that = this;
    const url = `/agency/dashboards/components/motorcycles${location.search}`;
    $.get(url, function(html) {
      $(that.motorcyclesTarget).html(html);
    });
  }
  loadBuses() {
    const that = this;
    const url = `/agency/dashboards/components/buses${location.search}`;
    $.get(url, function(html) {
      $(that.busesTarget).html(html);
    });
  }
  loadTrucks() {
    const that = this;
    const url = `/agency/dashboards/components/trucks${location.search}`;
    $.get(url, function(html) {
      $(that.trucksTarget).html(html);
    });
  }
  loadPersons() {
    const that = this;
    const url = `/agency/dashboards/components/persons${location.search}`;
    $.get(url, function(html) {
      $(that.personsTarget).html(html);
    });
  }
}