import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'location',
    'address',
    'latitude',
    'longitude'
  ]
  initialize() {
    this.marker = null;
    this.getLocation();
  }
  setLatitudeAndLongitude(latitude, longitude, that) {
    $(that.latitudeTarget).val(latitude);
    $(that.longitudeTarget).val(longitude);
  }
  geocode(event) {
    const that = this;
    that.clearMaker(that.marker);
    event.preventDefault();
    event.stopPropagation();
    const geocoder = new google.maps.Geocoder();
    const address = $(that.addressTarget).val();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        that.setLatitudeAndLongitude(location.lat(), location.lng(), that);
        that.map.setCenter(location);
        that.marker = new google.maps.Marker({
          map: that.map,
          position: location,
        });
      } else {
        alert("Geocode was not successful for the following reason: " + status);
      }
    });
  }
  getLocation() {
    const latitude = $(this.latitudeTarget).val();
    const longitude = $(this.longitudeTarget).val();
    this.position = { coords: { latitude: 19.4326, longitude: -99.1332 } };
    if(latitude != '' && latitude != '0'){
      this.position = { coords: { latitude: parseFloat(latitude), longitude: parseFloat(longitude) } };
      this.initializeMap(this.position, this);
      this.marker = new google.maps.Marker({
        map: this.map,
        position: { lat: this.position.coords.latitude, lng: this.position.coords.longitude},
      });
    }else{
      if (navigator.geolocation) {
        const that = this;
        navigator.geolocation.getCurrentPosition(function(position) {
          that.initializeMap(position, that);
        }, function() {
          that.gettingGeolocationError(that);
        });
      }else{
        // Default position of map is CDMX
        this.initializeMap(this.position, this);
      }
    }
  }
  gettingGeolocationError(that) {
    that.initializeMap(that.position, that);
  }
  initializeMap(position, that) {
    that.map = null;
    const mapOptions = {
      zoom: 14,
      center: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    that.map = new google.maps.Map(that.locationTarget, mapOptions);
    that.map.addListener("click", (event) => {
      const latLng = event.latLng;
      that.setLatitudeAndLongitude(latLng.lat(), latLng.lng(), that);
      that.clearMaker(that.marker);
      that.marker = new google.maps.Marker({
        position: latLng,
        map: that.map,
      });
      that.map.panTo(latLng);
    });
  }
  clearMaker(marker) {
    if (marker) {
      marker.setMap(null);
    }
  }
  next(event) {
    event.preventDefault();
    event.stopPropagation();
    const camera_id = $(event.currentTarget).attr('data-camera-id');
    const url = `/agency/cameras/${camera_id}/modal/wizard/steps/3`;
    $.get(url, function(html) {
      $('#cameras-wizard-step-3').replaceWith(html);
      App.wizard();
      $('#camera-wizard').wizard('next');
    });
  }
}