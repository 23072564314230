import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'trafficPerHourChart',
    'trafficPerHourTooltip'
  ];
  connect() {
    this.loadTrafficPerHourData();
    document.onmousemove = function(e) {
      window.mouse_x = e.pageX;
      window.mouse_y = e.pageY;
    }
  }
  loadTrafficPerHourData() {
    const data = JSON.parse(this.trafficPerHourChartTarget.dataset.json);
    this.generateChart(data);
  }
  generateChart(data) {
    const color1 = "#7F64B5";
    $.plot(this.trafficPerHourChartTarget,
      [{
        data: data.data,
      }],
      {
        series: {
          lines: {
            show: true,
            lineWidth: 2,
            fill: true,
            fillColor: {
              colors: [{
                opacity: 0.1
              }, {
                opacity: 0.1
              }
              ]
            }
          },
          points: {
            show: true
          },
          shadowSize: 0
        },
        legend:{
          show: false
        },
        grid: {
          labelMargin: 15,
          axisMargin: 500,
          hoverable: true,
          clickable: true,
          tickColor: "rgba(0,0,0,0.15)",
          borderWidth: 0
        },
        colors: [color1],
        xaxis: {
          ticks: data.ticks,
          tickDecimals: 0
        },
        yaxis: {
          ticks: 4,
          tickDecimals: 0
        }
      }
    );
    this.initializeTooltip(this);
  }
  initializeTooltip(that) {
    const tooltip_text = that.trafficPerHourTooltipTarget.dataset.text;
    $(that.trafficPerHourChartTarget).bind("plothover", function (_, pos, item) {
      if (!pos.x || !pos.y) {
        return;
      }
      if (item) {
        let x = Number(item.datapoint[0]),
          y = Number(item.datapoint[1]);

        $(that.trafficPerHourTooltipTarget).html(`${y} ${tooltip_text} ${x} Hrs.`)
          .css({ top: window.mouse_y-50, left: window.mouse_x })
          .fadeIn(200);
      } else {
        $(that.trafficPerHourTooltipTarget).hide();
      }
    })
  }
}