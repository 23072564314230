import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['search']
  change() {
    const term = $(this.searchTarget).val();
    if(term.length > 2 || term.length == 0) {
      this.search(term);
    }
  }
  search(term) {
    const url = `/agency/brand/campaigns.js?[q][name_cont]=${term}`
    $.ajax({
      url: url,
      type: 'get',
      success: function(html) {
        $('#brand-cameras-table').replaceWith(html);
      }
    });
  }
}