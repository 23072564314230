import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['modal', 'form']
  connect() {
    this.initDatepickers();
    $(this.modalTarget).on('hidden.bs.modal', this.cancel);
  }
  initDatepickers() {
    $(".datetimepicker").datetimepicker({
    	autoclose: true,
    	componentIcon: '.s7-date',
    	navIcons:{
    		rightIcon: 's7-angle-right',
    		leftIcon: 's7-angle-left'
    	}
    });
  }
  addCampaign(event) {
    event.preventDefault();
    event.stopPropagation();
    this.openModal();
  }
  cancel() {
    const that = this;
    const url = `/agency/brand/campaigns/new`;
    $.get(url, function(html) {
      $(that.formTarget).replaceWith(html);
    });
  }
  openModal() {
    console.log('Modal');
    $(this.modalTarget).modal('show');
  }
  edit(event) {
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    this.loadCampaign(id);
  }
  loadCampaign(id) {
    const that = this;
    if(id) {
      const url = `/agency/brand/campaigns/${id}/edit`;
      $.get(url, function(html) {
        $(that.formTarget).replaceWith(html);
        that.initDatepickers();
        that.openModal();
      });
    }
  }
}