import { Controller } from "stimulus";
import Taggle from "taggle";

export default class extends Controller {
  static targets = ['modal', 'users', 'campaigns']
  initialize() {
    this.initializeTaggle(this);
    const that = this;
    $(this.modalTarget).on('hidden.bs.modal', function(){
      that.resetModal();
    });
    this.initializeAssetAutocomplete();
  }
  initializeTaggle(that) {
    new Taggle(that.usersTarget, { placeholder: '', hiddenInputName: 'users[]' });
    new Taggle(that.campaignsTarget, { placeholder: '', hiddenInputName: 'campaigns[]' });
    // new Taggle(this.assetsTarget, { placeholder: '', hiddenInputName: 'assets[]' });
  }
  openModal() {
    $(this.modalTarget).modal('show');
  }
  resetModal() {
    const that = this;
    const url = `/agency/brands/new`;
    $.get(url, function(html) {
      $('#brand-modal').replaceWith(html);
      that.initializeTaggle(that);
      setTimeout(function(){
        $('#brand-modal').on('hidden.bs.modal', function(){
          that.resetModal();
        });
      }, 500)
    });
  }
  edit(event) {
    const that = this;
    event.preventDefault();
    event.stopPropagation();
    const id = $(event.currentTarget).attr('data-id');
    const url = `/agency/brands/${id}/edit`;
    $.get(url, function(html) {
      $('#brand-modal').replaceWith(html);
      that.initializeTaggle(that);
      setTimeout(function(){
        $('#brand-modal').on('hidden.bs.modal', function(){
          that.resetModal();
        });
      }, 500)
      $('#brand-modal').modal('show');
      that.initializeAssetAutocomplete();
    });
  }
  initializeAssetAutocomplete() {
    const that = this;
    const url = '/agency/asset/autocomplete';
    $(this.assetTarget).select2({
      ajax: {
        url: url,
        dataType: 'json',
        placeholder: 'Search for a asset',
        minimumInputLength: 3,
        processResults: function (data) {
          return {
            results: data
          };
        }
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      multiple: true,
      tags: false,
    });
  }
}