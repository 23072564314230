import { Controller } from "stimulus"

export default class extends Controller {
  fullScreen(event) {
    event.preventDefault();
    if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    } else if (this.element.mozRequestFullScreen) { /* Firefox */
      this.element.mozRequestFullScreen();
    } else if (this.element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      this.element.webkitRequestFullscreen();
    } else if (this.element.msRequestFullscreen) { /* IE/Edge */
      this.element.msRequestFullscreen();
    }
  }
  share(event){
    event.preventDefault()
    $('#share-panel-modal').modal('show');
  }
}